import React from "react";
import Caretdownup from "../../assets/icons/Caretdownup.png";

const ArrowDropUp = () => {
  return (
    <div className="dropDown__img">
      <img src={Caretdownup} alt="Caretdownup" />
    </div>
  );
};

export default ArrowDropUp;
